import styled from "styled-components";
import { Link } from "react-router-dom"


export const MainDiv = styled.div`
    background-color: #646765;
    min-height: 100vh;
`

export const ImgDiv = styled.div`
    width: 100%;
    height: 100vh;
    overflow: hidden;
    
    img{
        width: 100%;
        margin-top: -40%;
        @media(max-width: 648px){
        margin-top: -10%;

        }
        @media(max-width: 500px){
        margin-top: 0;

        }
    }
    @media(max-width: 480px){
          height: 80vh;
        }
    
`
export const TitleText = styled.div`
    width: 100%;
    text-align: center;
    color: white;
    padding-top: 50px;
    font-weight: 100;

    span{
        font-family: Raleway,sans-serif;
        font-size: 45px;
    }
`
export const ProductsDiv = styled.div`
    width: 700px;
    display: flex;
    gap: 40px;
    justify-content: space-between;
    margin: -10px auto;
    margin-top: 100px;
    padding-bottom: 100px;
    flex-wrap: wrap;
    border: none;
    @media(max-width: 700px){
      width: 100%;
    }
`







export const ProductsImgDiv = styled(Link)`
    width: 300px;
    height: 400px;
    position: relative;
    border-radius: 30px;
    overflow: hidden; 
    @media(max-width: 740px){
        margin: 0 auto;
    }
    @media(max-width: 320px){
        width: 270px;
        height: 270px;
    }

    img {
        height: 100%;
        border-radius: 30px;
        transition: filter 0.5s ease;
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        &:hover {
            filter: brightness(30%);
        }
    }

    &::after {
        font-weight: 700;
        font-size: 22px;
        content: ${props => "'" + props.$text + "'"};
        position: absolute;
        top: 30%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
        padding: 10px;
        border-radius: 5px;
        z-index: 10;
        opacity: 0; 
        transition: 0.5s ease; 
        pointer-events: none;
        text-align: center;
        width:90%;
        margin: 0 auto;
    }

    &:hover::after {
        opacity: 1; 
        top: 50%;
    }
`;



export const SingleProducts = styled(Link)`
  width: 700px;
  height:300px;
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;
  img {
    width: 100%;
  height:300px;

    border-radius: 30px;
    transition: filter 0.3s ease;
    cursor: pointer;
    position: absolute;
    &:hover {
      filter: brightness(30%);
    }
    @media (max-width: 640px) {
      width: 300px;
      height: 300px;
      object-fit: cover;
      left: 0;
      right: 0;
      margin: auto;
    }
    @media(max-width: 320px){
    width: 270px;
  height: 270px;
  }
  }

  &::after {
    font-weight: 700;
    font-size: 22px;
    content: ${props => "'" + props.$text + "'"};
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    padding: 10px;
    border-radius: 5px;
    z-index: 10;
    opacity: 0; 
    transition: 0.5s ease; 
    pointer-events: none;
    
  }

  &:hover::after {
    opacity: 1; 
    top: 60%;


  }
  @media (max-width: 740px) {
      padding-bottom: 100px;
      
    }
`;
