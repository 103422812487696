import React, { useState, useEffect, useRef } from 'react';
import { MainDiv, RamkaDiv } from './styled';
import { useTranslation } from 'react-i18next'

export const DepartmentsScroll = () => {
    const [isDivOverFiftyPercent, setIsDivOverFiftyPercent] = useState([false, false, false, false, false, false, false, false, false, false, false]);
    const divRefs = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)];
    const { t } = useTranslation()
    const [value, setValue] = useState()
    const [lang, setLang] = useState()
    useEffect(() => {
        setLang(localStorage.getItem('lang'))
    }, [t])
    useEffect(() => {
        const handleScroll = (index) => () => {
            if (divRefs[index].current) {
                const divBounding = divRefs[index].current.getBoundingClientRect();
                const windowHeight = window.innerHeight || document.documentElement.clientHeight;
                const divTop = divBounding.top;
                const divHeight = divBounding.height;
                const isOverHalfVisible = (divTop >= 0 && divTop < windowHeight / 1.5) || (divTop < 0 && Math.abs(divTop) < divHeight / 1.5);

                setIsDivOverFiftyPercent(prevState => {
                    const newState = [...prevState];
                    newState[index] = isOverHalfVisible;

                    if (isOverHalfVisible) {
                        newState.forEach((_, i) => {
                            if (i !== index) newState[i] = false;
                        });
                    }

                    return newState;
                });
            }
        };

        divRefs.forEach((ref, index) => {
            window.addEventListener('scroll', handleScroll(index));
        });

        return () => {
            divRefs.forEach((ref, index) => {
                window.removeEventListener('scroll', handleScroll(index));
            });
        };
    }, [divRefs]);
    useState(() => {
        const fetchData = async () => {
            try {
                const req = await fetch("https://server.mayyanart.com/home-get")
                const res = await req.json()
                setValue(res.home)
                
            } catch (error) {
                console.log(error);
            }
        }
        fetchData()
    }, [])


    return (
        <MainDiv $isDivOverFiftyPercent={isDivOverFiftyPercent}>
            {value?.map((e, i) => {
                if (e.activ && lang == "ru") {
                    return (

                        <RamkaDiv key={i} ref={divRefs[i]} $isDivOverFiftyPercent={isDivOverFiftyPercent[i]} $first={e.first}>
                            <span>{e.textTitleRU.toUpperCase()} <p>{e.textRU.toUpperCase()}</p></span>
                            <img src={`https://server.mayyanart.com/uploads/${e.src}`} alt="paintings" />
                        </RamkaDiv>

                    )
                } else if (e.activ && lang == "en") {
                    return (
                        <RamkaDiv key={i} ref={divRefs[i]} $isDivOverFiftyPercent={isDivOverFiftyPercent[i]} $first={e.first}>
                            <span>{e.textTitleEN.toUpperCase()} <p>{e.textEN.toUpperCase()}</p></span>
                            <img src={`https://server.mayyanart.com/uploads/${e.src}`} alt="paintings" />
                        </RamkaDiv>
                    )
                } else if (e.activ && lang == "am") {
                    return (
                        <RamkaDiv key={i} ref={divRefs[i]} $isDivOverFiftyPercent={isDivOverFiftyPercent[i]} $first={e.first}>
                            <span>{e.textTitleAM.toUpperCase()} <p>{e.textAM.toUpperCase()}</p></span>
                            <img src={`https://server.mayyanart.com/uploads/${e.src}`} alt="paintings" />
                        </RamkaDiv>
                    )
                }
            }
            )}
        </MainDiv>
    );
};
