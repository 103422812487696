import styled from "styled-components";

export const MainDiv = styled.div`
    width: 100%;
    min-height:100vh;
    background-color: ${({ $isDivOverFiftyPercent }) => {
        if ($isDivOverFiftyPercent[0]) return "#444b53";
        if ($isDivOverFiftyPercent[1]) return "#646765";
        if ($isDivOverFiftyPercent[2]) return "#7c807d";
        if ($isDivOverFiftyPercent[3]) return "#959896";
        if ($isDivOverFiftyPercent[4]) return "#aeb0af";
        return "#444b53";
    }};
    transition: 0.8s;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px ;
    
`

export const RamkaDiv = styled.div`
    width: 100%;
    min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    text-align: center;
    transition: 0.8s;
    padding-top: 80px;
    opacity: ${({ $isDivOverFiftyPercent }) => $isDivOverFiftyPercent ? "1" : "0"};
    
    img{
        width: 40%;
        transition: 0.8s;
        margin-right: ${({ $isDivOverFiftyPercent }) => $isDivOverFiftyPercent ? "30px" : "0"};
        color: ${({ $isDivOverFiftyPercent }) => $isDivOverFiftyPercent ? "#b9a195 " : "black"};
        border-radius: 20px;
        @media(max-width: 800px){
            margin-top: 20px;
            margin-right: 0;
            width: 90%;
        }


    }
    span{
        transition: 0.8s;
        width: 40%;
        
        margin-left: ${({ $isDivOverFiftyPercent }) => $isDivOverFiftyPercent ? "30px" : "0"};
        color: ${({ $first }) => $first ? "#b9a195 " : "black"};
        font-family: 'Lato', sans-serif;
        font-size: 35px;
        font-weight: 300;
        color: white;

        @media(max-width: 988px){
            font-size: 25px;
        }
        @media(max-width: 800px){
            font-size: 25px;
            margin-left: 0;
            width: 90%;
        }
        p{
            font-size: 18px;
            margin-top: 20px;
            font-weight: 250;
            color: white;
            line-height: 25px;
            @media(max-width: 988px){
            font-size: 15px;
        }
        }
    }
    @media(max-width: 800px){
        flex-direction: column;
        justify-content: center;
    }
`